import React from 'react';
import { Container, Typography} from '@material-ui/core';
import Layout from '../components/layout';
import Link from 'gatsby-link';
import SEO from '../components/seo'

let year = new Date()

const privacyPage = () => (
    <Layout location="">
        <SEO title='Privacy Policy' description='blix Privacy Policy' />

        <Container fixed style={{paddingTop: '150px', paddingBottom: '50px'}}>
            <Typography color='textPrimary'>Privacy Policy</Typography>
            <Typography color='textSecondary'>
            <div id='privacy2'>

            <p>The Blix Service is a combined email and messaging application (the “Application”) and Cloud-based 
                service developed and owned by Blix Inc. (“Blix”). The Blix Service  helps you manage your email, 
                calendar and other content from your device using various functionalities and features (the “Services”) 
                we provide through the Application. In order to facilitate the Services, we also have our server-side 
                operations (our “Cloud”). This Privacy Policy explains how we collect, store, use and otherwise process 
                personal data and maintain your privacy and security. </p>
            <p>
                <p>This Privacy Policy contains the following information.</p>
            <ol>
                <li>What information do we collect?</li>
                <li>How do we use the information we collect?</li>
                <li>Whether and how the information is shared</li>
                <li>Communications</li>
                <li>Your rights as a data subject</li>
                <li>Data Retention</li>
                <li>Security</li>
                <li>Transfers of information across boundaries</li>
                <li>Data Controller/Processor</li>
                <li>Children</li>
                <li>CCPA</li>
                <li>Updates to Privacy Policy</li>
                <li>Merger and Contact Information</li>
            </ol> 
            </p>
            <p>Capitalized terms that are not otherwise defined in this Privacy Policy have the meaning given 
                to them in the Blix Terms of Service. If you do not have the authority to bind your employer 
                or other entity, or do not agree to be bound by the Blix Terms of Service, or this Privacy Policy, 
                do not accept these terms, do not install the Application and do not access or use the Blix Service, 
                including the Blix website.
</p>
            <h3>1. What Information do we collect?</h3>
            <h4>1.1 Information that you provide to us:</h4>
            <p>We collect various types of personal data regarding our Users as well as data regarding Visitors to our Sites. Such data is typically collected and generated through your interaction with the Blix Service. Specifically, we collect the following categories of data (which, to the extent it relates to an identified or identifiable individual, is deemed as “Personal Data“):
            </p>
            <p>When you install or access the Application and set up your account, you provide us with your email account addresses (for the accounts you wish to manage through the Application), your display name of each of your email accounts (Optional) – this could be your actual name, or a nickname you choose, Your Photo (Optional) – if you choose to upload it as your account avatar. You may choose to upload another photo, but you need to make sure you have the appropriate rights to use this photo.
            </p>
            <p>
                <ul style={{listStyle: 'circle'}}>
                    <li>You may contact us or seek our customer support  such as by using “Contact Us” or send us support requests, or provide us with feedback, reviews, or answers to surveys or promotions, including by contacting us on social media channels, by posting on any of our online public forums or communities, by sending an e-mail to any of our designated addresses, or any other form of communication. W we may obtain and maintain any data you choose to provide, including your name, your email addresses, as well as information about your mobile device such as its device type OS type details on a problem you are experiencing and any information concerning your use of the Application. </li> 
                    <li>When you contact us to seek our customer support, we may obtain and maintain any information you choose to provide, including your name, your email addresses, as well as information about your mobile device such as its device type OS type and any information concerning your use of the Application.</li>
                    <li>Sync Services – you may use the optional Sync Services to allow synchronization of data such as your Application settings, contacts, remarks and tasks which are securely stored on our Cloud. We may ask you to provide your phone number to send SMS text message verification.</li>
                    <li>When you create a group, we save on our Cloud the group name, avatar, notes and email addresses of the group members.</li>
                    <li>When you use the Blix Smart Filter feature, you provide us with the email addresses you interact with. You can disable the Blix Smart Filter at any time from the Application settings.</li>
                    <li>You can report emails whether misclassified, safe or malicious, to our Cloud for analysis, sharing, debugging and classification. You can share a link to an email by uploading it to a Cloud sharing service.</li>
                    <li>Instant Push Services (Optional) for iOS and OAuth supported email accounts – if you are using imap and exchange email accounts on iOS, or OAuth supported email accounts and configure your settings to allow push notifications (the Blix Cloud sends push notifications to your mobile device when you have a new email), we will we keep either the OAuth token or encrypted credentials. When using the Instant Push Services for those accounts, we receive the email headers including to, from, subject and a small portion of the body. The information will be deleted immediately after sending the Push notification.</li>
                    <li>Data concerning third-party apps: If, when using the Services, you or your Administrator choose to integrate your account with third-party apps, we will connect and integrate these third-party apps to ours. The third-party providers of these integrations may receive certain relevant data about or from your Account or share certain relevant data from your Account on their services with our Services, depending on the nature and purpose of such integrations. If you do not wish your data to be shared with such third-party apps, please contact your Administrator.</li>
                </ul>
            </p>
            <p>You may provide us with additional User data such as a company or entity billing details, business needs and preferences.  To the extent that such data relates to a business or entity (e.g. business bank account), we do not regard this as Personal Data and this Privacy Policy will not be applicable.
            </p>
            <h4>Are you obligated to provide us with this private data?</h4>
            
            <p>You are not obligated to provide us with any of the above information.</p>
            
            <p>However, we will not be able to provide you with the Application and the Blix Services or part of them, as applicable, if you do not agree, or withdraw your consent, or request to object (all as detailed under section 5 with respect to your rights) to our processing of these data items.</p>            
            <h4>1.2 Information that we collect from third parties:</h4>
            
            <h4>Analytics for Web and Mobile</h4>
            <p>We use web and mobile analytics software to measure traffic and usage patterns and to help us better understand the functionality of the Blix Service on your device or general information regarding how users use the Blix Service. When using our Website, the web analytic tools collect information sent by your browser, including the pages you visit and other information that assists us in improving the Website. The Application on your device may collect information such as what kind of device you are using, how often you use the Blix Service, the way you use the Bix Service (what functions are used for example) performance data (technical issues concerning crashes, operation metrics, memory consumption and that type of information), and which store the Application was downloaded from and send this information to the Cloud. We use this information only to administer, improve, monitor and analyze the use of the Blix Service.</p>            
            <h4>Cookie Information</h4>
            <p>We use cookies and similar technologies in our Website that help us collect information. The Website may also include cookies and similar tracking technologies of third parties such as Google Analytics which may collect information about you via the Website. For more details please see our <Link style={{color: '#1AA3FF'}} to='/cookie-policy/'>Cookie Policy</Link>.</p>
            
            <h4>Non-Hardware Identifiers</h4>
            <p>When you access the Blix Service on a mobile device (like a smart-phones or tablet), we may access, create, monitor or remotely store identifiers such as Instance ID or GUID which uniquely identify our app. Only Blix can access these identifiers. We do not use any hardware identifiers such as Unique Device Identifier (UDID), SSAID (Android ID) and IMEI and we have no way to identify the specific hardware you use.</p>
            <p>These identifiers persist on your device and our Cloud to help you log in faster and enhance your navigation through the Application. Some features of the Blix Service may not function properly if these identifiers are impaired or disabled.</p>
            <p>We frequently and securely check the Application version with our Cloud to recommend an upgrade. We also securely check for licenses and premium features to allow or prohibit functionalities.</p>
            <h3>2. How do we use the information we collect?</h3>
            <p>
                <ul style={{listStyle: 'circle'}}>
                    <li>We use all of the above information to operate, maintain, and provide to you with the Blix Service features and functionality.</li> 
                    <li>We use the email address to establish the accounts you wish to use within the Blix Service, and let you manage your email account through the Blix Service.</li>
                    <li>We use your display name to display the different account names as you assigned them.</li>
                    <li>We may use the above information to respond to your requests and messages, including with respect to your rights as data subject, as well as to initiate contact with you on issues related to the Services or support and maintenance issues.</li>
                    <li>We may use the information you provide to allow certain functions to perform as intended. For instance, for the Smart Filter – to compare your email contacts with spam email lists we have on our Cloud; for the Sync service to be able to perform the requested synchronizations; for the send later feature to be able to save and send the email at a later time you defined, etc.</li>
                    <li>To enhance and support or data security measures, including to prevent, detect and fight fraud or other illegal or unauthorized activities.</li>
                    <li>To ensure legal compliance – from our side (to legal requirements that apply to us and to our obligations under the Terms of Use) and from your side (Compliance with laws applicable to you and with the Terms of Use).</li>
                </ul>
            </p>

            <p>
                <strong>Our Legal Bases for Processing</strong>
            </p>

            <p>The following are the legal reasons why we process your information in the manners specified above:</p>
            <p>
                <ol>
                    <li>Performance of a contract to which Blix and you, the data subject, are parties. Many of the uses described above are actions that involve processing of your information in order to meet our undertakings to you and respond to your requests and indications within the Blix Service.</li>
                    <li>Consent – some of the information you provide is based on your agreement and willingness to provide it. In addition, we may ask you from time to time, through the Service interface, to provide your consent for specific uses.</li>
                </ol>
            </p>

            <h3>3. Whether and How Your Information is Shared?</h3>
            <p>We will never share, rent or sell your information to third parties outside of Blix and its group companies (including subsidiaries and affiliates) without your consent, except as noted below:</p>

            <p>
                <ol>
                    <li>
                        <strong>Service Providers:</strong> We engage third-party business partners to help us provide you with the Blix Services, for instance, Amazon AWS (Cloud hosting and related services), Google Analytics for usage analytics and statistics, Content Delivery Networks (CDN) , fraud detection, billing and payment processing services (collectively, “Service Providers“). These Service Providers will be given limited access to your Personal Data, depending on each of their specific roles and purposes in facilitating and enhancing our Services, and may only use it for such limited purposes as determined in our agreements with them. Our Service Providers shall be deemed as ‘Data Processors’ in circumstances where Blix assumes the role of ‘Data Controller’; and where Blix acts as the Data Processor for the Customer, the Service Provider shall be deemed our ‘Sub-Processor’ (as further described in Section 9 below). A list of Blix’s Sub-Processors is available at https://blix.net/legal/subprocessors.
                    </li>
                    <li>
                        <strong>Compliance with the Law:</strong> We may disclose your information if we believe it is necessary in order to comply with the law, such as to comply with a subpoena, regulation or legal request, respond to a government request, to address fraud or security issues, to protect the safety of any person, to enforce our agreements with you; to investigate, prevent, or take other action regarding illegal activity, suspected fraud or other wrongdoing or to protect our own rights or property. If you are located in the EU, we may only do so based on legal requirements specified above of EU authorities. This includes sharing such information with our legal counsel.
                    </li>
                    <li>
                        <strong>Business Transfers:</strong> If the Blix Service is involved in a merger, acquisition, bankruptcy, reorganization or sale of assets, your information may be transferred as part of that transaction to a successor in interest and to the applicable legal authorities as well as legal counsel and other professionals involved, such as accountants, and other officers of the government or of the applicable judiciary instance.

                    </li>
                </ol>
            </p>
            <p>
                <strong>Links to Third Party Websites</strong>
            </p>
            <p>This section does not relate to information that we directly convey to third parties.</p>
            <p>We may place links on the Blix website. When you click on a link to a third-party website from our website, you will be redirected to such third-party site, and your activity and use on the linked website is governed by that website’s terms of use, privacy policies and other policies, not by those of Blix. We encourage you to review their privacy and use policies.</p>

            <h3>4. Communications</h3>
            <p>If you are located in the EU, the following shall apply only if you marked your consent to receive such communications.</p>
            <p>If you are located in the US or elsewhere in the world, the following shall apply for as long as you have not opted-out of this activity:</p>
            <p>We may use the information we collect or receive to communicate with you directly. For example, you may receive emails from us containing company updates, promotions, marketing, or special offers. We do this to be sure our communications and our products get better over time. If you would rather not receive these emails, you may opt-out of receiving them by following the unsubscribe link located at the bottom of each communication or by emailing us at <a style={{color: '#1AA3FF'}} href="mailto:privacy@blix.net">privacy@blix.net</a>. Even after you opt-out from receiving communications messages from us, you will continue to receive administrative messages from us regarding our services (such as requirement to upgrade the Application).</p>

            <h3>5. Your rights as a data subject</h3>
            <p>
                <ul style={{listStyle: 'circle'}}>
                    <li>You may access and update your information by visiting your account settings within the Application or by emailing us at the email address listed below.</li> 
                    <li>You can use the <a style={{color: '#1AA3FF'}} href="https://panel.blix.net/">panel</a> tool here to view or remove information stored on our Cloud about your email account.</li> 
                    <li>In case our processing is based on your consent, you have the right to withdraw your consent at any time, by contacting us via email.</li> 
                    <li>You have the right to object to the processing of your Personal Data for direct marketing purposes at any time.</li> 
                    <li>You have the right to object to our processing of your Personal Data if processing is based on our legitimate interests.</li> 
                    <li>You have the right of portability with respect to your Personal Data, in case it is processed by automatic means, and if processing is based on your consent.</li> 
                    <li>You have the right to request that we erase all of your Personal Data that we keep, without undue delay, unless processing is necessary as further detailed under “Data Retention”</li> 
                    <li>In certain countries, you have a right to lodge a complaint with the appropriate supervisory authority in charge of data protection, in case you think that we are breaching any of your rights relating to your Personal Data. If you are located in the EEA, a complaint may be lodged in the country which is your place of residence, your place of work or place of the alleged infringement.</li> 
                </ul>
            </p>
            <p>
                If you have questions on how to exercise your rights, please contact <a style={{color: '#1AA3FF'}} href="mailto:privacy@blix.net">privacy@blix.net</a>. We will respond to your access request without undue delay, or within 30 days at the latest (as the law may require).
            </p>

            <h3>6. Data Retention</h3>
            <p>We will retain your information for as long as we need it for legitimate business purposes and as permitted by applicable law. Once no longer used as stated herein, it will be either deleted or completely de-identified in a manner that does not allow re-identification thereof.  Except as required by law or our agreement directly with you, we will not be obliged to retain your Personal Data for any particular period, and we are free to securely delete it or restrict access to it for any reason and at any time, with or without notice to you.
            </p>
            <p>We will also retain and use your information in the following instances:</p>
            <p>
                <ol>
                    <li>Keep evidence to our compliance with applicable law (for instance, records of consents to our Terms, Privacy Policy and other similar consents are kept for five years).</li>
                    <li>If there is an outstanding issue, claim or dispute requiring us to keep the relevant information until it is resolved.</li>
                    <li>The information must be kept for our legitimate business interests, such as fraud prevention and enhancing users’ safety and security. For example, information may need to be kept to prevent a user who was banned for unsafe behavior or security incidents from opening a new account.</li>
                </ol>
            </p>
            <p>Specifically, the following information items are retained for the following time periods:</p>

            <table style={{width: '100%'}}>
                <tr>
                    <td>Optional Functionality</td>
                    <td>Data Item</td>
                    <td>Retention period</td>
                </tr>
                <tr>
                    <td>Instant Push Services for iOS and OAuth supported accounts (optional)</td>
                    <td>Email header (to, from, title, portion of the body)</td>
                    <td>Deleted immediately upon push completion</td>
                </tr>
                <tr>
                    <td>Settings Backup (optional)</td>
                    <td>Your phone number</td>
                    <td>As long as required to perform the Services</td>
                </tr>
                <tr>
                    <td>Report an email (optional)</td>
                    <td>Reported email	</td>
                    <td>As long as required to perform the Services</td>
                </tr>
              
            </table>

            <h3>7. Security</h3>
            <p>
            Security is extremely important to us. We use industry leading security practices to preserve the integrity of all your data. For example, when you transmit sensitive information and when you send and receive emails, Blix encrypts the transmission of that information using secure socket layer technology (SSL). However, no method of electronic transmission or storage is 100% secure, so we cannot guarantee absolute security. Blix is not responsible for the functionality or security measures of any third party. If you have questions about this feel free to contact us at <a style={{color: '#1AA3FF'}} href="mailto:privacy@blix.net">privacy@blix.net.</a></p>

            <h3>8. Transfer of Personal Data across boundaries</h3>
            <p>Your information collected through the Blix Services may be stored and processed in the United States or any other countries in which we or our affiliates operate. To provide the Blix Service to you, we may transfer Personal Data to affiliated entities, or to other third parties across borders and from your country or jurisdiction to other countries or jurisdictions around the world. If you are located in the European Union or other regions with laws governing data collection and use that may differ from U.S. law, please note that we may transfer information, including Personal Data, to a country and jurisdiction that does not have the same data protection laws as your jurisdiction, and you consent to the transfer of information to the U.S. or any other country in which we or our parent, subsidiaries, affiliates or service providers maintain facilities and the use and disclosure of information about you as described in this Privacy Policy.</p>

            <p>
                <strong>Our legal basis for performing such transfer across boundaries are:</strong>
            </p>
            <p>
                <ol>
                    <li>Model Clauses. With some of our processors, we use standard contract clauses, that are binding standards of processing of Personal Data committed to contractually by third parties processing information for us and on our behalf, approved by the European Commission.</li>
                    <li>In the absence of Model Clauses, your consent. By using the Services, you agree and understand that your information may be transferred from the EEA or other countries in which you may be using the Services, to other countries outside your own location (including outside the EEA), and specifically to the USA. You agree to such transfer, and you understand that data processed in the USA is subject to different data protection laws, which may sometimes grant lesser degree of protection that the privacy laws and regulations applicable within the EEA for example.  Nevertheless, Blix is committed to protection your Personal Data in accordance with this Privacy Policy and reasonable industry standards, regardless of any lesser legal requirements that may apply in your jurisdiction.</li>
                </ol>
            </p>

            <h3>9. Data Controller/Processor </h3>
            <p>We are the Controller of our Users Personal Data, and certain types of User data; we are the Processor of any Personal Data contained in User data processed on behalf of our Customer. </p>
            <p>Data protection laws and regulations including GDPR distinguish between two main roles for parties processing Personal Data: the “Data Controller”, who determines the purposes and means of processing; and the “Data Processor”, who processes the data on behalf of the Data Controller. Below we explain how these roles apply to the Blix Service, to the extent that such laws and regulations apply. </p>
            <p>Blix is the “Data Controller” of its Customers Personal Data, as well as User data consisting of user profile, contact details, preferences and analytics data. With respect to such data, we assume the responsibilities of Data Controller (solely to the extent applicable under law), as set forth in this Privacy Policy. In such instances, our Service Providers processing such data will assume the role of “Data Processor”.</p>
            <p>If any of our Users upload or submit Personal Data or other content to our Blix Service which includes Personal Data (e.g., sending a message to another user with personal details), such data will only be processed by Blix on behalf of our Customer, which is the owner of the respective Account, along with all other User data processed on such Customer’s behalf. </p>
            <p>In such instances, our Customer shall be deemed the “Data Controller” of such data, and Blix will process such data on the Customer’s behalf, as its “Data Processor”, in accordance with its reasonable instructions, subject to our Terms of Service, our Data Processing Agreement with such Customer (to the extent applicable) and other commercial agreements. Blix’s Service Providers shall act as designated Sub-Processors in these instances. The Customer will be responsible for meeting any legal requirements applicable to Data Controllers (such as establishing a legal basis for processing and responding to Data Subject Rights requests concerning the data they control). </p>

            <h3>10. Children</h3>
            <p>Our Services are not directed to persons under 16. We do not knowingly collect Personal Data from children under 16. If we become aware that a child under 16 has provided us with Personal Data, we will take steps to remove such information and terminate the child’s account. If you learn that your child has provided us with Personal Data without your consent, please contact us at <a style={{color: '#1AA3FF'}} href="mailto:privacy@blix.net">privacy@blix.net</a>.</p>

            <h3>11. CCPA</h3>
            <p>If you are resident of the State of California you may be entitled to additional privacy disclosures under the California Consumer Privacy Act (“CCPA”) after January 1, 2020.  The CCPA provides for additional protections for Personal Data, which the statute defines as your IP address, account or social security number, drivers license or passport information, online activity, employment data, demographic information and others.  Under the CCPA:    (1) you have the right to know what Personal Data Blix has collected about you as a User and if it has been sold; (2) you have the right to a copy of the Personal Data that Blix has collected about you; (3) you may opt out of any transfer or sale of your Personal Data; (4) you may request that Blix delete any of your Personal Data; (5) if any Personal Data is transferred or sold to third parties, you have a right to know the categories of such third parties; (6) you have the right to know the reasons Personal Data has been collected; and (7) you have the right to equal service and pricing if you assert any rights under the CCPA.  Note, the deletion of certain Personal Data may disrupt some or all of the functions and features of the Blix Service.</p>
            <p>If you are a California resident and wish to obtain any of the information described above regarding the collection, use and disclosure of your Personal Data, please make such a request by sending an email to <a style={{color: '#1AA3FF'}} href="mailto:privacy@blix.net">privacy@blix.net.</a></p>
            
            <h3>12. Updates to Privacy Policy</h3>
            <p>We may modify or update this Privacy Policy from time to time to reflect the changes in our business and practices, and to keep up with any new compliance requirements. When we change the policy in a material manner, we will update the ‘last modified’ date at the bottom of this page, post a notice to the Website or the Blix Service and/or reach out to you via email or otherwise. The most up-to-date version can always be found at <Link style={{color: '#1AA3FF'}} to='/privacy'>blix.net/privacy</Link></p>

            <h3>13. Merger and Contact Information</h3>
            <p>This Privacy Policy is part of, and incorporates, all the definitions, terms, conditions, and remedies of the Blix Terms of Use.  Your consent to the Terms of Use includes your agreement to this Privacy Policy. If you have any questions, please send your inquiries to <a style={{color: '#1AA3FF'}} href="mailto:privacy@blix.net">privacy@blix.net</a>, subject: support of privacy policy. We will aim to respond to you within 30 days from receipt of the request.  </p>

            <hr />

            <p>Last Updated: August 15th, 2019</p>
            <p>© {year.getFullYear()} Blix Inc.</p>
        </div>
        </Typography>
    </Container>
    </Layout>
);

export default privacyPage;